import {
	HttpClient,
	HttpEvent,
	HttpEventType,
	HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { bytesInMB, fileUploadChunkSizeInMB } from 'app/common/app-constants';
import { combineCompleteUrl } from 'app/common/utils/utils';
import { isNullOrUndefined } from 'app/common/utils/utils.object';
import {
	ChunkedFileUploadRequest,
	ChunkedFileUploadResponse
} from '@twrx-api-models';
import { UploadFileResponse } from 'app/models/dto/upload-file-response';
import { CancellationError } from 'app/models/errors/cancellation-error';
import { saveAs } from 'file-saver';
import {
	concatMap,
	filter,
	from,
	map,
	mergeMap,
	Observable,
	takeUntil,
	throwError
} from 'rxjs';

@Injectable()
export class HttpApiService {
	constructor(private readonly http: HttpClient) {}

	public get<T>(
		url: string,
		data?: unknown,
		cancellationSubject?: Observable<void>
	): Observable<T> {
		return this.http
			.get<T>(combineCompleteUrl(url, data))
			.pipe(this.applyCancellationSubject(cancellationSubject));
	}

	public post<T>(
		url: string,
		data?: object,
		cancellationSubject?: Observable<void>
	): Observable<T> {
		return this.http
			.post<T>(combineCompleteUrl(url), data)
			.pipe(this.applyCancellationSubject(cancellationSubject));
	}

	public put<T>(
		url: string,
		data: object,
		cancellationSubject?: Observable<void>
	): Observable<T> {
		return this.http
			.put<T>(combineCompleteUrl(url), data)
			.pipe(this.applyCancellationSubject(cancellationSubject));
	}

	public delete<T>(
		url: string,
		data: unknown,
		cancellationSubject?: Observable<void>
	): Observable<T> {
		return this.http
			.delete<T>(combineCompleteUrl(url, data))
			.pipe(this.applyCancellationSubject(cancellationSubject));
	}

	public downloadFile(
		url: string,
		data?: unknown,
		cancellationSubject?: Observable<void>
	): Observable<number> {
		return this.http
			.post(combineCompleteUrl(url), data, {
				responseType:   'arraybuffer',
				reportProgress: true,
				observe:        'events'
			})
			.pipe(
				map((x: HttpEvent<ArrayBuffer>) =>
					this.getDownloadFileProgress(x)
				),
				filter(x => x !== null),
				this.applyCancellationSubject(cancellationSubject)
			);
	}

	public uploadFile<T>(
		url: string,
		file: File,
		data?: object,
		cancellationSubject?: Observable<void>
	): Observable<UploadFileResponse<T>> {
		const formData = new FormData();

		formData.append('file', file, file.name);
		Object.keys(data)
			.filter(x => data[x])
			.forEach(k => {
				formData.append(k, data[k]);
			});

		return this.http
			.post<T>(combineCompleteUrl(url), formData, {
				reportProgress: true,
				observe:        'events'
			})
			.pipe(
				map(x => this.getUploadFileResponse(x)),
				filter(x => x !== null),
				this.applyCancellationSubject(cancellationSubject)
			);
	}

	public chunkedUploadFile<T>(
		url: string,
		file: File,
		uploadRequest?: ChunkedFileUploadRequest,
		cancellationSubject?: Observable<void>
	): Observable<UploadFileResponse<T>> {
		const chunkSize = fileUploadChunkSizeInMB * bytesInMB;
		const fileSize = file.size;
		const chunksCount = Math.ceil(fileSize / chunkSize);

		uploadRequest = {
			...uploadRequest,
			chunkIndex: 0,
			fileSize,
			chunksCount
		};

		const getProgress = (chunkIndex: number): number =>
			((chunkIndex + 1) / chunksCount) * 100;

		let request = this.chunkedFileUpload<T>(
			file,
			url,
			chunkSize,
			uploadRequest
		).pipe(
			map(({ data, cacheKey }) => ({
				data,
				cacheKey,
				progress: getProgress(0)
			}))
		);

		if (chunksCount !== 1) {
			request = request.pipe(
				mergeMap(({ cacheKey }: ChunkedFileUploadResponse<T>) =>
					from(
						[...Array(chunksCount).keys()].filter(x => x !== 0)
					).pipe(
						concatMap(chunkIndex =>
							this.chunkedFileUpload<T>(file, url, chunkSize, {
								...uploadRequest,
								chunkIndex,
								cacheKey
							}).pipe(
								map(({ data }) => ({
									data,
									cacheKey: null,
									progress: getProgress(chunkIndex)
								}))
							)
						)
					)
				)
			);
		}

		return request.pipe(this.applyCancellationSubject(cancellationSubject));
	}

	private getDownloadFileProgress(event: HttpEvent<ArrayBuffer>): number {
		if (event.type === HttpEventType.DownloadProgress) {
			return Math.round((100 * event.loaded) / event.total);
		}

		if (event.type === HttpEventType.Response) {
			const fileName = this.getFileName(event.headers);
			const contentType = event.headers.get('content-type');
			const blob = new Blob([event.body], {
				type: contentType
			});

			saveAs(blob, fileName);
		}

		return null;
	}

	private getUploadFileResponse<T>(
		event: HttpEvent<T>
	): UploadFileResponse<T> {
		if (event.type === HttpEventType.UploadProgress) {
			return {
				progress: Math.round((100 * event.loaded) / event.total)
			} as UploadFileResponse<T>;
		}

		if (event.type === HttpEventType.Response) {
			return {
				data: event.body
			} as UploadFileResponse<T>;
		}

		return null;
	}

	private chunkedFileUpload<T>(
		file: File,
		url: string,
		chunkSize: number,
		data?: ChunkedFileUploadRequest
	): Observable<ChunkedFileUploadResponse<T>> {
		const totalFileSize = file.size;

		const { chunkIndex } = data;
		const start = chunkIndex * chunkSize;
		const end =
			start + chunkSize >= totalFileSize
				? totalFileSize
				: start + chunkSize;

		const filePart = file.slice(start, end);

		const formData = new FormData();

		formData.append('file', filePart, file.name);
		Object.keys(data)
			.filter(x => !isNullOrUndefined(data[x]))
			.forEach(k => {
				formData.append(k, data[k]);
			});

		return this.http.post<ChunkedFileUploadResponse<T>>(
			combineCompleteUrl(url),
			formData
		);
	}

	private getFileName(headers: HttpHeaders): string {
		let fileName = headers
			.get('Content-Disposition')
			.split(';')[1]
			.trim()
			.split('=')[1]
			.split('"')[1];

		if (!fileName) {
			fileName = headers
				.get('Content-Disposition')
				.split(';')[1]
				.trim()
				.split('=')[1];
		}

		return fileName;
	}

	// Cancel request when cancellationSubject is emitted and throw an CancellationError.
	private readonly applyCancellationSubject =
		(cancellationSubject?: Observable<void>) =>
		<T>(source: Observable<T>): Observable<T> =>
			isNullOrUndefined(cancellationSubject)
				? source
				: source.pipe(
						takeUntil(
							cancellationSubject.pipe(
								mergeMap(() =>
									throwError(() => new CancellationError())
								)
							)
						)
					);
}
