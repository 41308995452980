import { createAction, props } from '@ngrx/store';
import {
	GenerateReportRequest,
	ReportModel,
	SearchRequest,
	SearchResponse,
	ReportStorageRequest
} from '@twrx-api-models';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum ReportActions {
	LoadReports = '[Report] LoadReports',
	ReportsLoaded = '[Report] ReportsLoaded',

	GenerateReport = '[Report] GenerateReport',
	ReportGenerated = '[Report] ReportGenerated',

	DownloadReportFile = '[Report] DownloadReportFile',
	ReportFileDownloaded = '[Report] ReportFileDownloaded',

	ErrorOccurred = '[Report] ErrorOccurred'
}

export const loadReports = createAction(
	ReportActions.LoadReports,
	props<ActionRequestPayload<SearchRequest>>()
);

export const reportsLoaded = createAction(
	ReportActions.ReportsLoaded,
	props<ActionResponsePayload<SearchResponse<ReportModel>>>()
);

export const generateReport = createAction(
	ReportActions.GenerateReport,
	props<ActionRequestPayload<GenerateReportRequest>>()
);

export const reportGenerated = createAction(
	ReportActions.ReportGenerated,
	props<ActionResponsePayload<ReportModel>>()
);

export const downloadReportFile = createAction(
	ReportActions.DownloadReportFile,
	props<ActionRequestPayload<ReportStorageRequest>>()
);

export const reportFileDownloaded = createAction(
	ReportActions.ReportFileDownloaded
);

export const errorOccurred = createAction(ReportActions.ErrorOccurred);
