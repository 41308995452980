import {
	EnvironmentProviders,
	ErrorHandler,
	Injectable,
	Provider
} from '@angular/core';
import {
	AngularPlugin,
	ApplicationinsightsAngularpluginErrorService
} from '@microsoft/applicationinsights-angularplugin-js';
import {
	ApplicationInsights,
	ITelemetryPlugin
} from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class ApplicationInsightsService {
	private readonly angularPlugin = new AngularPlugin();
	private readonly appInsights = new ApplicationInsights({
		config: {
			connectionString:          environment.applicationInsights.connectionString,
			disableCorrelationHeaders: true,
			extensions:                [this.angularPlugin as unknown as ITelemetryPlugin],
			extensionConfig:           {
				[this.angularPlugin.identifier]: {
					router:        this.router,
					errorServices: [new ErrorHandler()]
				}
			}
		}
	});

	constructor(private router: Router) {}

	loadAppInsights(): void {
		if (!environment.production) {
			return;
		}

		this.appInsights.loadAppInsights();
		this.appInsights.addTelemetryInitializer(envelope => {
			envelope.tags['ai.cloud.role'] =
				environment.applicationInsights.roleName;
			envelope.tags['ai.cloud.roleInstance'] =
				environment.applicationInsights.roleInstance;
		});
	}
}

export const applicationInsightsProviders = (): Array<
	Provider | EnvironmentProviders
> => {
	return environment.production
		? [
				ApplicationInsightsService,
				{
					provide:  ErrorHandler,
					useClass: ApplicationinsightsAngularpluginErrorService
				}
			]
		: [ApplicationInsightsService];
};
