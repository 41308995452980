import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { defaultNavigation } from 'app/core/navigation/navigation.data';
import { PermissionModel } from '@twrx-api-models';
import { AccountRoutes } from 'app/common/routes';

import { IAuth0State } from './auth0.state';
import { auth0FeatureKey } from './auth0.reducers';

export const selectAuth0 = createFeatureSelector<IAuth0State>(auth0FeatureKey);

export const selectIsAuth0Loading = createSelector(
	selectAuth0,
	state => state.isLoading
);

export const selectIsAuthenticated = createSelector(
	selectAuth0,
	state => state.isAuthenticated
);

export const selectPermissions = createSelector(
	selectAuth0,
	state => state.permissions
);

export const selectAuth0Token = createSelector(
	selectAuth0,
	state => state.token
);

export const selectAuthenticatedRedirectUrlOrNoPermissions = createSelector(
	selectPermissions,
	permissions =>
		defaultNavigation.find(x =>
			x.meta?.allowedPermissions?.some(mp =>
				permissions.some(sp => isPermitted(sp, mp))
			)
		)?.link ?? AccountRoutes.NoPermissions
);

export const selectNavigation = createSelector(
	selectAuth0,
	(state: IAuth0State): FuseNavigationItem[] =>
		filterNavigations(defaultNavigation, state.permissions)
);

export const selectError = createSelector(selectAuth0, state => state.error);

const filterNavigations = (
	items: FuseNavigationItem[],
	permissions: PermissionModel[]
): FuseNavigationItem[] => {
	const rootNavigations = items.filter(item =>
		isNavigationPermitted(item, permissions)
	);
	const parentNavigations =
		rootNavigations.length === items.length
			? []
			: items
					.filter(
						item =>
							item.meta === undefined
							&& item.children !== undefined
					)
					.map(item => ({
						...item,
						children: filterNavigations(item.children, permissions)
					}))
					.filter(item => item.children.length > 0);

	return [...rootNavigations, ...parentNavigations];
};

const isNavigationPermitted = (
	item: FuseNavigationItem,
	permissions: PermissionModel[]
): boolean =>
	item.meta?.allowedPermissions.some((allowed: PermissionModel) =>
		permissions?.some(required => isPermitted(required, allowed))
	) ?? false;

const isPermitted = (p1: PermissionModel, p2: PermissionModel): boolean =>
	p1.scope === p2.scope && Boolean(p1.permission & p2.permission);
