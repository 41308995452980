export enum ImportFileType {
	RebateLoad = 1,
	RejectLoad = 2,
	Ndc = 3,
	RebatesEligible = 4,
	ProvidersUpdate = 5,
	Facility = 6,
	DailyClaims = 7,
	MediSpan = 8,
	DailySlateRxInbound = 9,
	RebateLoadImport = 10,
	RebateAggregatorNdcRateImport = 11
}
