export enum RebatesLoadFileModelStatus {
	New = 1,
	Deleting = 2,
	UploadingProgress = 3,
	UploadingFailed = 4,
	WaitingForValidation = 10,
	ValidationProgress = 11,
	Invalid = 12,
	ValidationFailed = 13,
	FileDuplicatesValidation = 21,
	FileDuplicatesValidationProgress = 22,
	FileDuplicatesValidationFailed = 23,
	FileDuplicatesResolvingProgress = 24,
	FileDuplicatesResolvingFailed = 25,
	CrossFileDuplicatesValidation = 31,
	CrossFileDuplicatesValidationProgress = 32,
	CrossFileDuplicatesValidationFailed = 33,
	CrossDuplicateResolvingProgress = 34,
	CrossDuplicateResolvingFailed = 35,
	WaitingForAggregationInformation = 41,
	AggregationFailed = 42,
	WaitingForConversionInformation = 51,
	ConversionFailed = 53,
	AberrantQuantitiesReview = 61,
	ConversionApproval = 62,
	FinalReview = 63,
	ProcessingProgress = 64,
	ProcessingFailed = 65,
	Processed = 66,
	PharmacyReview = 71,
	PharmacyValidationProgress = 72,
	PharmacyValidationFailed = 73,
	ArchivingInProgress = 81,
	ArchivingFailed = 82,
	Archived = 83,
	QueuencedToUnarchive = 91,
	UnarchivingInProgrerss = 92,
	UnarchivingFailed = 93,
	Unarchived = 94
}
