import { isNullOrUndefined } from './utils.object';

export const isSomeCollectionNotEmpty = (arrays: unknown[][]): boolean =>
	!isNullOrUndefined(arrays)
	&& arrays.some((array: unknown[]) => !isCollectionEmpty(array));

export const isCollectionEmpty = (array: unknown[]): boolean =>
	!isNullOrUndefined(array) && array.length === 0;

export const isArrayNullOrEmpty = <T>(array: Array<T>): boolean =>
	isNullOrUndefined(array) || array.length === 0;

export const intersect = <T>(a: T[], b: T[]): T[] =>
	[...new Set(a)].filter(x => new Set(b).has(x));

export const isIntersect = <T>(a: T[], b: T[]): boolean =>
	a.some(x => b.includes(x));

export const isSubSet = <T>(a: T[], b: T[]): boolean =>
	a.every(x => b.includes(x));

export const isPrimitiveArraysEqual = <T>(
	array1: T[],
	array2: T[]
): boolean => {
	if (!array1 && !array2) {
		return true;
	}

	if (!array1 || !array2) {
		return false;
	}

	if (array1.length !== array2.length) {
		return false;
	} else {
		const isInArray1 = array1.every(item => array2.includes(item));
		const isInArray2 = array2.every(item => array1.includes(item));

		return isInArray1 && isInArray2;
	}
};

export const getAllIndexes = <T>(array: Array<T>, value: T): Array<number> =>
	array
		.map((val, index) => ({ val, index }))
		.filter(({ val }) => val === value)
		.map(({ index }) => index);

export const getRange = (start: number, end: number): number[] => {
	return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};
