import { Injectable } from '@angular/core';
import {
	FilterSearchRequest,
	SearchRequest,
	SearchResponse
} from '@twrx-api-models';
import { Observable } from 'rxjs';
import { CommonApiRoutes } from 'app/services/api/api-routes';

import { HttpApiService } from './http-api.service';

@Injectable()
export abstract class BaseApiCRUDService<
	TId,
	TModel,
	TCreateModel extends object,
	TCreateResult,
	TUpdateModel extends object,
	TUpdateResult,
	TFilterModel,
	TSearchModel
> {
	protected abstract rootRoute: string;

	protected constructor(protected httpApiService: HttpApiService) {}

	public getById(
		id: TId,
		cancellationSubject: Observable<void>
	): Observable<TModel> {
		return this.httpApiService.get<TModel>(
			this.rootRoute,
			id,
			cancellationSubject
		);
	}

	public search(
		request: SearchRequest | FilterSearchRequest<TFilterModel>,
		cancellationSubject: Observable<void>
	): Observable<SearchResponse<TSearchModel>> {
		return this.httpApiService.post<SearchResponse<TSearchModel>>(
			`${this.rootRoute}/${CommonApiRoutes.Search}`,
			request,
			cancellationSubject
		);
	}

	public create(
		model: TCreateModel,
		cancellationSubject: Observable<void>
	): Observable<TCreateResult> {
		return this.httpApiService.post<TCreateResult>(
			this.rootRoute,
			model,
			cancellationSubject
		);
	}

	public update(
		model: TUpdateModel,
		cancellationSubject: Observable<void>
	): Observable<TUpdateResult> {
		return this.httpApiService.put<TUpdateResult>(
			this.rootRoute,
			model,
			cancellationSubject
		);
	}

	public delete(
		id: TId,
		cancellationSubject: Observable<void>
	): Observable<void> {
		return this.httpApiService.delete(
			this.rootRoute,
			id,
			cancellationSubject
		);
	}
}
