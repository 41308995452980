import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	HostBinding,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { VerticalNavigationBasicItemComponent } from 'app/layout/layouts/classic/vertical/components/basic/basic.component';
import { VerticalNavigationDividerItemComponent } from 'app/layout/layouts/classic/vertical/components/divider/divider.component';
import { VerticalNavigationGroupItemComponent } from 'app/layout/layouts/classic/vertical/components/group/group.component';
import { VerticalNavigationSpacerItemComponent } from 'app/layout/layouts/classic/vertical/components/spacer/spacer.component';
import { VerticalNavigationComponent } from 'app/layout/layouts/classic/vertical/vertical.component';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
	selector:        'app-vertical-navigation-collapsable-item',
	templateUrl:     './collapsable.component.html',
	animations:      fuseAnimations,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports:         [
		NgClass,
		MatTooltipModule,
		NgIf,
		MatIconModule,
		NgFor,
		VerticalNavigationBasicItemComponent,
		forwardRef(() => VerticalNavigationCollapsableItemComponent),
		VerticalNavigationDividerItemComponent,
		VerticalNavigationGroupItemComponent,
		VerticalNavigationSpacerItemComponent,
		VerticalNavigationBasicItemComponent
	]
})
export class VerticalNavigationCollapsableItemComponent
	implements OnInit, OnDestroy
{
	static ngAcceptInputType_autoCollapse: BooleanInput;

	@Input() autoCollapse: boolean;
	@Input() item: FuseNavigationItem;
	@Input() name: string;

	isCollapsed: boolean = true;
	isExpanded: boolean = false;
	private verticalNavigationComponent: VerticalNavigationComponent;
	private unsubscribeAll: Subject<unknown> = new Subject<unknown>();

	/**
	 * Constructor
	 */
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private router: Router,
		private fuseNavigationService: FuseNavigationService
	) {}

	/**
	 * Host binding for component classes
	 */
	@HostBinding('class') get classList(): { [key: string]: boolean } {
		return {
			'fuse-vertical-navigation-item-collapsed': this.isCollapsed,
			'fuse-vertical-navigation-item-expanded':  this.isExpanded
		};
	}

	ngOnInit(): void {
		// Get the parent navigation component
		this.verticalNavigationComponent =
			this.fuseNavigationService.getComponent(this.name);

		// If the item has a children that has a matching url with the current url, expand...
		if (this.hasActiveChild(this.item, this.router.url)) {
			this.expand();
		}
		// Otherwise...
		else {
			// If the autoCollapse is on, collapse...
			if (this.autoCollapse) {
				this.collapse();
			}
		}

		// Listen for the onCollapsableItemCollapsed from the service
		this.verticalNavigationComponent.onCollapsableItemCollapsed
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(collapsedItem => {
				// Check if the collapsed item is null
				if (collapsedItem === null) {
					return;
				}

				// Collapse if this is a children of the collapsed item
				if (this.isChildrenOf(collapsedItem, this.item)) {
					this.collapse();
				}
			});

		// Listen for the onCollapsableItemExpanded from the service if the autoCollapse is on
		if (this.autoCollapse) {
			this.verticalNavigationComponent.onCollapsableItemExpanded
				.pipe(takeUntil(this.unsubscribeAll))
				.subscribe(expandedItem => {
					// Check if the expanded item is null
					if (expandedItem === null) {
						return;
					}

					// Check if this is a parent of the expanded item
					if (this.isChildrenOf(this.item, expandedItem)) {
						return;
					}

					// Check if this has a children with a matching url with the current active url
					if (this.hasActiveChild(this.item, this.router.url)) {
						return;
					}

					// Check if this is the expanded item
					if (this.item === expandedItem) {
						return;
					}

					// If none of the above conditions are matched, collapse this item
					this.collapse();
				});
		}

		// Attach a listener to the NavigationEnd event
		this.router.events
			.pipe(
				filter(
					(event): event is NavigationEnd =>
						event instanceof NavigationEnd
				),
				takeUntil(this.unsubscribeAll)
			)
			.subscribe((event: NavigationEnd) => {
				// If the item has a children that has a matching url with the current url, expand...
				if (this.hasActiveChild(this.item, event.urlAfterRedirects)) {
					this.expand();
				}
				// Otherwise...
				else {
					// If the autoCollapse is on, collapse...
					if (this.autoCollapse) {
						this.collapse();
					}
				}
			});

		// Subscribe to onRefreshed on the navigation component
		this.verticalNavigationComponent.onRefreshed
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				// Mark for check
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this.unsubscribeAll.next(null);
		this.unsubscribeAll.complete();
	}

	collapse(): void {
		// Return if the item is disabled
		if (this.item.disabled) {
			return;
		}

		// Return if the item is already collapsed
		if (this.isCollapsed) {
			return;
		}

		// Collapse it
		this.isCollapsed = true;
		this.isExpanded = !this.isCollapsed;

		// Mark for check
		this.changeDetectorRef.markForCheck();

		// Execute the observable
		this.verticalNavigationComponent.onCollapsableItemCollapsed.next(
			this.item
		);
	}

	expand(): void {
		// Return if the item is disabled
		if (this.item.disabled) {
			return;
		}

		// Return if the item is already expanded
		if (!this.isCollapsed) {
			return;
		}

		// Expand it
		this.isCollapsed = false;
		this.isExpanded = !this.isCollapsed;

		// Mark for check
		this.changeDetectorRef.markForCheck();

		// Execute the observable
		this.verticalNavigationComponent.onCollapsableItemExpanded.next(
			this.item
		);
	}

	toggleCollapsable(): void {
		// Toggle collapse/expand
		if (this.isCollapsed) {
			this.expand();
		} else {
			this.collapse();
		}
	}

	trackByFn(index: number, item: FuseNavigationItem): unknown {
		return item.id || item.children || index;
	}

	/**
	 * Check if the given item has the given url
	 * in one of its children
	 *
	 * @param item
	 * @param currentUrl
	 * @private
	 */
	private hasActiveChild(
		item: FuseNavigationItem,
		currentUrl: string
	): boolean {
		const children = item.children;

		if (!children) {
			return false;
		}

		for (const child of children) {
			if (child.children) {
				if (this.hasActiveChild(child, currentUrl)) {
					return true;
				}
			}

			// Check if the child has a link and is active
			if (
				child.link
				&& this.router.isActive(child.link, child.exactMatch || false)
			) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Check if this is a children
	 * of the given item
	 *
	 * @param parent
	 * @param item
	 * @private
	 */
	private isChildrenOf(
		parent: FuseNavigationItem,
		item: FuseNavigationItem
	): boolean {
		const children = parent.children;

		if (!children) {
			return false;
		}

		if (children.indexOf(item) > -1) {
			return true;
		}

		for (const child of children) {
			if (child.children) {
				if (this.isChildrenOf(child, item)) {
					return true;
				}
			}
		}

		return false;
	}
}
