import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationEvents } from 'app/common/notification.constants';
import { FileGeneratedNotification } from '@twrx-api-models';
import { generatedFileStatusUpdated } from 'app/store/account/account.actions';
import { IAccountState } from 'app/store/account/account.state';
import { ActionResponsePayload } from 'app/store/action-response-payload';

import { SignalRService } from './base/signalr-service';

@Injectable({
	providedIn: 'root'
})
export class GeneratedFileNotificationService {
	constructor(
		private readonly signalrService: SignalRService,
		private readonly store: Store<IAccountState>
	) {}

	public registerHandlers(): void {
		if (this.signalrService.disabled) {
			return;
		}

		this.signalrService
			.onEvent<FileGeneratedNotification>(
				NotificationEvents.FileGenerated
			)
			.subscribe(x => {
				this.store.dispatch(
					generatedFileStatusUpdated(new ActionResponsePayload(x))
				);
			});

		console.log('[File Generating] signalr events registered');
	}
}
