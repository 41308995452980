import { createAction, props } from '@ngrx/store';
import {
	SearchResponse,
	FilterSearchRequest,
	CreateFormularyNdcModel,
	UpdateFormularyNdcModel,
	FormularyNdcStoreModel,
	FormularyNdcSearchFilterModel,
	FormularyNdcImportResultModel,
	FormularyNdcHistorySearchFilterModel,
	FormularyNdcHistoryModel
} from '@twrx-api-models';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { FormularyNdcImportModel } from 'app/models/dto/formulary-ndc-import-model';

export enum FormularyNdcActions {
	LoadFormularyNdcList = '[FormularyNdc] LoadFormularyNdcList',
	FormularyNdcListLoaded = '[FormularyNdc] FormularyNdcListLoaded',

	CreateFormularyNdc = '[FormularyNdc] CreateFormularyNdc',
	FormularyNdcCreated = '[FormularyNdc] FormularyNdcCreated',

	UpdateFormularyNdc = '[FormularyNdc] UpdateFormularyNdc',
	FormularyNdcUpdated = '[FormularyNdc] FormularyNdcUpdated',

	PreloadFormularyNdc = '[FormularyNdc] PreloadFormularyNdc',
	PreloadedFormularyNdcNotFound = '[FormularyNdc] PreloadedFormularyNdcNotFound',
	FormularyNdcPreloaded = '[FormularyNdc] FormularyNdcPreloaded',

	LoadFormularyNdc = '[FormularyNdc] LoadFormularyNdc',
	FormularyNdcNotFound = '[FormularyNdc] FormularyNdcNotFound',
	FormularyNdcLoaded = '[FormularyNdc] FormularyNdcLoaded',

	LoadFormularyNdcHistory = '[FormularyNdc] LoadFormularyNdcHistory',
	FormularyNdcHistoryLoaded = '[FormularyNdc] FormularyNdcHistoryLoaded',

	StartFormularyNdcListFileGeneration = '[FormularyNdc] StartFormularyNdcListFileGeneration',
	FormularyNdcListFileGenerationStarted = '[FormularyNdc] FormularyNdcListFileGenerationStarted',

	ImportFormularyNdcList = '[FormularyNdc] ImportFormularyNdcList',
	FormularyNdcListImporting = '[FormularyNdc] FormularyNdcListImporting',
	FormularyNdcListImported = '[FormularyNdc] FormularyNdcListImported',

	ErrorOccurred = '[FormularyNdc] ErrorOccurred'
}

export const loadFormularyNdcList = createAction(
	FormularyNdcActions.LoadFormularyNdcList,
	props<
		ActionRequestPayload<FilterSearchRequest<FormularyNdcSearchFilterModel>>
	>()
);

export const formularyNdcListLoaded = createAction(
	FormularyNdcActions.FormularyNdcListLoaded,
	props<ActionResponsePayload<SearchResponse<FormularyNdcStoreModel>>>()
);

export const createFormularyNdc = createAction(
	FormularyNdcActions.CreateFormularyNdc,
	props<ActionRequestPayload<CreateFormularyNdcModel>>()
);

export const formularyNdcCreated = createAction(
	FormularyNdcActions.FormularyNdcCreated
);

export const updateFormularyNdc = createAction(
	FormularyNdcActions.UpdateFormularyNdc,
	props<ActionRequestPayload<UpdateFormularyNdcModel>>()
);

export const formularyNdcUpdated = createAction(
	FormularyNdcActions.FormularyNdcUpdated
);

export const loadFormularyNdc = createAction(
	FormularyNdcActions.LoadFormularyNdc,
	props<ActionRequestPayload<number>>()
);

export const formularyNdcNotFound = createAction(
	FormularyNdcActions.FormularyNdcNotFound
);

export const formularyNdcLoaded = createAction(
	FormularyNdcActions.FormularyNdcLoaded,
	props<ActionResponsePayload<FormularyNdcStoreModel>>()
);

export const loadFormularyNdcHistory = createAction(
	FormularyNdcActions.LoadFormularyNdcHistory,
	props<
		ActionRequestPayload<
			FilterSearchRequest<FormularyNdcHistorySearchFilterModel>
		>
	>()
);

export const formularyNdcHistoryLoaded = createAction(
	FormularyNdcActions.FormularyNdcHistoryLoaded,
	props<ActionResponsePayload<SearchResponse<FormularyNdcHistoryModel>>>()
);

export const preloadFormularyNdc = createAction(
	FormularyNdcActions.PreloadFormularyNdc,
	props<ActionRequestPayload<number>>()
);

export const preloadedFormularyNdcNotFound = createAction(
	FormularyNdcActions.PreloadedFormularyNdcNotFound
);

export const formularyNdcPreloaded = createAction(
	FormularyNdcActions.FormularyNdcPreloaded,
	props<ActionResponsePayload<FormularyNdcStoreModel>>()
);

export const startFormularyNdcListFileGeneration = createAction(
	FormularyNdcActions.StartFormularyNdcListFileGeneration,
	props<ActionRequestPayload<FormularyNdcSearchFilterModel>>()
);

export const formularyNdcListFileGenerationStarted = createAction(
	FormularyNdcActions.FormularyNdcListFileGenerationStarted
);

export const importFormularyNdcList = createAction(
	FormularyNdcActions.ImportFormularyNdcList,
	props<ActionRequestPayload<FormularyNdcImportModel>>()
);

export const formularyNdcListImporting = createAction(
	FormularyNdcActions.FormularyNdcListImporting,
	props<ActionResponsePayload<number>>()
);

export const formularyNdcListImported = createAction(
	FormularyNdcActions.FormularyNdcListImported,
	props<ActionResponsePayload<FormularyNdcImportResultModel>>()
);

export const errorOccurred = createAction(FormularyNdcActions.ErrorOccurred);
