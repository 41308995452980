export enum NcpdpProviderType {
	NotUsed = 0,
	CommunityRetailPharmacy = 1,
	CommunityRetailPharmacy2 = 2,
	CommunityRetailPharmacy3 = 3,
	LongTermCarePharmacy = 4,
	MailOrderPharmacy = 5,
	HomeInfusionTherapyProvider = 6,
	NonPharmacyDispensingSize = 7,
	IndianHealthServiceTribalUrbanIndianHealthPharmacy = 8,
	DepartmentOfVeteransAffairsPharmacy = 9,
	InstitutionalPharmacy = 10,
	InstitutionalPharmacy2 = 11,
	ManagedCareOrganizationPharmacy = 12,
	Dme = 13,
	ClinicPharmacy = 14,
	SpecialtyPharmacy = 15,
	NuclearPharmacy = 16,
	MilitaryUsCoastGuardPharmacy = 17,
	CompoundingPharmacy = 18,
	OxygenEquipment = 19,
	NursingFacilitySupplies = 20,
	CustomizedEquipment = 21,
	DialysisEquipment = 22,
	ParenteralAndEnteralNutrition = 23
}
