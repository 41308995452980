import { createAction, props } from '@ngrx/store';
import {
	CreateBdcModel,
	BdcModel,
	SearchRequest,
	SearchResponse,
	UpdateBdcModel,
	BdcSearchModel
} from '@twrx-api-models';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum BdcActions {
	LoadBdc = '[Bdc] LoadBdc',
	BdcLoaded = '[Bdc] BdcLoaded',

	PreLoadBdc = '[Bdc] PreLoadBdc',
	BdcPreLoaded = '[Bdc] BdcPreLoaded',
	PreLoadBdcNotFound = '[Bdc] PreLoadBdcNotFound',

	LoadBdcById = '[Bdc] LoadBdcById',
	LoadBdcNotFound = '[Bdc] LoadBdcNotFound',
	BdcByIdLoaded = '[Bdc] BdcByIdLoaded',

	CreateBdc = '[Bdc] CreateBdc',
	BdcCreated = '[Bdc] BdcCreated',

	UpdateBdc = '[Bdc] UpdateBdc',
	BdcUpdated = '[Bdc] BdcUpdated',

	DeleteBdc = '[Bdc] DeleteBdc',
	BdcDeleted = '[Bdc] BdcDeleted',

	ErrorOccurred = '[Bdc] ErrorOccurred'
}

export const loadBdcList = createAction(
	BdcActions.LoadBdc,
	props<ActionRequestPayload<SearchRequest>>()
);

export const bdcLoaded = createAction(
	BdcActions.BdcLoaded,
	props<ActionResponsePayload<SearchResponse<BdcSearchModel>>>()
);

export const preLoadBdcById = createAction(
	BdcActions.PreLoadBdc,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadBdcNotFound = createAction(BdcActions.PreLoadBdcNotFound);

export const bdcByIdPreLoaded = createAction(
	BdcActions.BdcPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<BdcModel>>>()
);

export const loadBdcListById = createAction(
	BdcActions.LoadBdcById,
	props<ActionRequestPayload<number>>()
);

export const loadBdcNotFound = createAction(BdcActions.LoadBdcNotFound);

export const bdcByIdLoaded = createAction(
	BdcActions.BdcByIdLoaded,
	props<ActionResponsePayload<BdcModel>>()
);

export const createBdc = createAction(
	BdcActions.CreateBdc,
	props<ActionRequestPayload<CreateBdcModel>>()
);

export const bdcCreated = createAction(BdcActions.BdcCreated);

export const updateBdc = createAction(
	BdcActions.UpdateBdc,
	props<ActionRequestPayload<UpdateBdcModel>>()
);

export const bdcUpdated = createAction(BdcActions.BdcUpdated);

export const deleteBdc = createAction(
	BdcActions.DeleteBdc,
	props<ActionRequestPayload<number>>()
);

export const bdcDeleted = createAction(BdcActions.BdcDeleted);

export const errorOccurred = createAction(BdcActions.ErrorOccurred);
