import { createAction, props } from '@ngrx/store';
import {
	CheckResultModel,
	DownloadHistoryRequest,
	FilterBulkUpdateRequest,
	FilterSearchRequest,
	HistoryTransactionModel,
	RebateSearchModel,
	RebateUpdateModel,
	RebatesSearchFilterModel,
	SearchRequest,
	SearchResponse,
	RebatesTotalCountFilterModel,
	RebatesBulkDeleteRequest
} from '@twrx-api-models';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum RebatesUpdateActions {
	LoadRebates = '[RebatesUpdate] LoadRebates',
	RebatesLoaded = '[RebatesUpdate] RebatesLoaded',

	UpdateRebates = '[RebatesUpdate] UpdateRebates',
	RebatesUpdated = '[RebatesUpdate] RebatesUpdated',

	StartRebatesBulkUpdate = '[RebatesUpdate] BulkUpdateRebates',
	RebatesBulkUpdatedStarted = '[RebatesUpdate] RebatesBulkUpdated',

	RebatesBulkOperationFinished = '[RebatesUpdate] RebatesBulkOperationFinished',

	CheckRebatesBulkUpdateDisabled = '[RebatesUpdate] CheckRebatesBulkUpdateDisabled',
	RebatesBulkUpdatedDisabledChecked = '[RebatesUpdate] RebatesBulkUpdatedDisabledChecked',

	DeleteRebate = '[RebatesUpdate] DeleteRebate',
	RebateDeleted = '[RebatesUpdate] RebateDeleted',

	BulkDeleteRebates = '[RebatesUpdate] BulkDeleteRebates',
	RebatesBulkDeleted = '[RebatesUpdate] RebatesBulkDeleted',

	PreloadRebatesHistory = '[RebatesUpdate] PreloadRebatesHistory',
	RebatesHistoryPreloaded = '[RebatesUpdate] RebatesHistoryPreloaded',

	LoadRebatesHistory = '[RebatesUpdate] LoadRebatesHistory',
	RebatesHistoryLoaded = '[RebatesUpdate] RebatesHistoryLoaded',

	DownloadRebatesHistory = '[RebatesUpdate] DownloadRebatesHistory',
	RebatesHistoryDownloaded = '[RebatesUpdate] RebatesHistoryDownloaded',

	LoadRebatesTotalCount = '[RebatesUpdate] LoadRebatesTotalCount',
	RebatesTotalCountLoaded = '[RebatesUpdate] RebatesTotalCountLoaded',

	ErrorOccurred = '[RebatesUpdate] Error Occurred'
}

export const loadRebates = createAction(
	RebatesUpdateActions.LoadRebates,
	props<ActionRequestPayload<FilterSearchRequest<RebatesSearchFilterModel>>>()
);

export const rebatesLoaded = createAction(
	RebatesUpdateActions.RebatesLoaded,
	props<ActionResponsePayload<SearchResponse<RebateSearchModel>>>()
);

export const updateRebates = createAction(
	RebatesUpdateActions.UpdateRebates,
	props<ActionRequestPayload<RebateUpdateModel[]>>()
);

export const rebatesUpdated = createAction(RebatesUpdateActions.RebatesUpdated);

export const startRebatesUpdate = createAction(
	RebatesUpdateActions.StartRebatesBulkUpdate,
	props<
		ActionRequestPayload<FilterBulkUpdateRequest<RebatesSearchFilterModel>>
	>()
);

export const rebatesBulkUpdatedStarted = createAction(
	RebatesUpdateActions.RebatesBulkUpdatedStarted
);

export const rebatesBulkOperationFinished = createAction(
	RebatesUpdateActions.RebatesBulkOperationFinished
);

export const checkRebatesBulkUpdateDisabled = createAction(
	RebatesUpdateActions.CheckRebatesBulkUpdateDisabled,
	props<ActionRequestPayload<void>>()
);

export const rebatesBulkUpdatedDisabledChecked = createAction(
	RebatesUpdateActions.RebatesBulkUpdatedDisabledChecked,
	props<ActionResponsePayload<CheckResultModel>>()
);

export const deleteRebate = createAction(
	RebatesUpdateActions.DeleteRebate,
	props<ActionRequestPayload<number>>()
);

export const rebateDeleted = createAction(RebatesUpdateActions.RebateDeleted);

export const bulkDeleteRebates = createAction(
	RebatesUpdateActions.BulkDeleteRebates,
	props<ActionRequestPayload<RebatesBulkDeleteRequest>>()
);

export const rebatesBulkDeleted = createAction(
	RebatesUpdateActions.RebatesBulkDeleted
);

export const preloadRebatesHistory = createAction(
	RebatesUpdateActions.PreloadRebatesHistory
);

export const rebatesHistoryPreloaded = createAction(
	RebatesUpdateActions.RebatesHistoryPreloaded,
	props<ActionResponsePayload<SearchResponse<HistoryTransactionModel>>>()
);

export const loadRebatesHistory = createAction(
	RebatesUpdateActions.LoadRebatesHistory,
	props<ActionRequestPayload<SearchRequest>>()
);

export const rebatesHistoryLoaded = createAction(
	RebatesUpdateActions.RebatesHistoryLoaded,
	props<ActionResponsePayload<SearchResponse<HistoryTransactionModel>>>()
);

export const downloadRebatesHistory = createAction(
	RebatesUpdateActions.DownloadRebatesHistory,
	props<ActionRequestPayload<DownloadHistoryRequest>>()
);

export const rebatesHistoryDownloaded = createAction(
	RebatesUpdateActions.RebatesHistoryDownloaded
);

export const loadRebatesTotalCount = createAction(
	RebatesUpdateActions.LoadRebatesTotalCount,
	props<ActionRequestPayload<RebatesTotalCountFilterModel>>()
);

export const rebatesTotalCountLoaded = createAction(
	RebatesUpdateActions.RebatesTotalCountLoaded,
	props<ActionResponsePayload<number>>()
);

export const errorOccurred = createAction(RebatesUpdateActions.ErrorOccurred);
