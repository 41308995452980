import {
	AccountModel,
	AuthorizedAccountModel,
	GeneratedFileModel,
	PermissionModel,
	SignInModel
} from '@twrx-api-models';

export interface IAccountState {
	loadingRequestCount: number;
	status: AuthStatus;
	signInData: SignInModel;
	account: AuthorizedAccountModel;
	token: string;
	refreshToken: string;
	permissions: PermissionModel[];
	redirectUrl: string;
	currentUser: AccountModel;
	passwordResetUrl: string;
	historyEmail: string;
	previousRoute: string;
	generatedFile: GeneratedFileModel;
	disabled: boolean;
}

export const initialAccountState: IAccountState = {
	loadingRequestCount: 0,
	status:              null,
	signInData:          null,
	account:             null,
	token:               null,
	refreshToken:        null,
	permissions:         null,
	redirectUrl:         null,
	currentUser:         null,
	passwordResetUrl:    null,
	historyEmail:        null,
	previousRoute:       null,
	generatedFile:       null,
	disabled:            false
};

export enum AuthStatus {
	Authenticated = 1,
	SignedOut = 2
}
