import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { listItemTooltipShowDelay } from 'app/common/app-constants';
import { BaseApiComponent } from 'app/common/base/base-api-component';
import { AccountRoutes } from 'app/common/routes';
import { filterNullOrUndefined } from 'app/extensions/pipe-operators';
import { loadCurrentUserInfo } from 'app/store/account/account.actions';
import { selectCurrentUser } from 'app/store/account/account.selectors';
import { IAccountState } from 'app/store/account/account.state';
import { signOut } from 'app/store/auth-proxy/auth-proxy.actions';
import { distinctUntilChanged, map, Observable } from 'rxjs';

@UntilDestroy()
@Component({
	selector:        'app-profile',
	templateUrl:     './profile.component.html',
	encapsulation:   ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs:        'user',
	imports:         [
		MatButtonModule,
		MatIconModule,
		MatBadgeModule,
		MatMenuModule,
		MatListModule,
		RouterLink,
		TranslocoModule,
		AsyncPipe,
		MatTooltip
	]
})
export class ProfileComponent extends BaseApiComponent implements OnInit {
	@Input() showAvatar: boolean = true;

	public readonly listItemTooltipShowDelay = listItemTooltipShowDelay;

	public userEmail: Observable<string>;
	public accountRoutes = AccountRoutes;

	constructor(private readonly store: Store<IAccountState>) {
		super();
	}

	ngOnInit(): void {
		this.store.dispatch(loadCurrentUserInfo(this.createRequestPayload()));

		this.userEmail = this.store.select(selectCurrentUser).pipe(
			untilDestroyed(this),
			filterNullOrUndefined(),
			map(({ email }) => email),
			distinctUntilChanged()
		);
	}

	public signOut(): void {
		this.store.dispatch(signOut());
	}
}
