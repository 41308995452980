import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	IsActiveMatchOptions,
	RouterLink,
	RouterLinkActive
} from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { VerticalNavigationComponent } from 'app/layout/layouts/classic/vertical/vertical.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector:        'app-vertical-navigation-basic-item',
	templateUrl:     './basic.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports:         [
		NgClass,
		NgIf,
		RouterLink,
		RouterLinkActive,
		MatTooltipModule,
		NgTemplateOutlet,
		MatIconModule
	]
})
export class VerticalNavigationBasicItemComponent implements OnInit, OnDestroy {
	@Input() item: FuseNavigationItem;
	@Input() name: string;

	isActiveMatchOptions: IsActiveMatchOptions;
	private verticalNavigationComponent: VerticalNavigationComponent;
	private unsubscribeAll: Subject<unknown> = new Subject<unknown>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private fuseNavigationService: FuseNavigationService,
		private fuseUtilsService: FuseUtilsService
	) {
		// Set the equivalent of {exact: false} as default for active match options.
		// We are not assigning the item.isActiveMatchOptions directly to the
		// [routerLinkActiveOptions] because if it's "undefined" initially, the router
		// will throw an error and stop working.
		this.isActiveMatchOptions = this.fuseUtilsService.subsetMatchOptions;
	}

	ngOnInit(): void {
		// Set the "isActiveMatchOptions" either from item's
		// "isActiveMatchOptions" or the equivalent form of
		// item's "exactMatch" option
		this.isActiveMatchOptions =
			(this.item.isActiveMatchOptions ?? this.item.exactMatch)
				? this.fuseUtilsService.exactMatchOptions
				: this.fuseUtilsService.subsetMatchOptions;

		// Get the parent navigation component
		this.verticalNavigationComponent =
			this.fuseNavigationService.getComponent(this.name);

		// Mark for check
		this.changeDetectorRef.markForCheck();

		// Subscribe to onRefreshed on the navigation component
		this.verticalNavigationComponent.onRefreshed
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				// Mark for check
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this.unsubscribeAll.next(null);
		this.unsubscribeAll.complete();
	}
}
