import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
	FuseNavigationItem,
	FuseNavigationService,
	FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Store } from '@ngrx/store';
import { Navigation } from 'app/core/navigation/navigation.types';
import { GeneratedFilesComponent } from 'app/layout/common/generated-files/generated-files.component';
import { ProfileComponent } from 'app/layout/common/user/profile.component';
import { VerticalNavigationComponent } from 'app/layout/layouts/classic/vertical/vertical.component';
import { IAccountState } from 'app/store/account/account.state';
import { selectNavigation } from 'app/store/auth-proxy/auth-proxy.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
	selector:      'app-classic-layout',
	templateUrl:   './classic.component.html',
	encapsulation: ViewEncapsulation.None,
	imports:       [
		FuseLoadingBarComponent,
		MatButtonModule,
		MatIconModule,
		ProfileComponent,
		NgIf,
		RouterOutlet,
		AsyncPipe,
		VerticalNavigationComponent,
		GeneratedFilesComponent
	],
	animations: fuseAnimations
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
	isScreenSmall: boolean;
	navigation: Navigation;
	public navigationItems: Observable<FuseNavigationItem[]>;

	private unsubscribeAll: Subject<unknown> = new Subject<unknown>();

	constructor(
		private fuseMediaWatcherService: FuseMediaWatcherService,
		private fuseNavigationService: FuseNavigationService,
		private readonly store: Store<IAccountState>
	) {}

	ngOnInit(): void {
		this.navigationItems = this.store.select(selectNavigation);

		// Subscribe to media changes
		this.fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Check if the screen is small
				this.isScreenSmall = !matchingAliases.includes('md');
			});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this.unsubscribeAll.next(null);
		this.unsubscribeAll.complete();
	}

	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation =
			this.fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				name
			);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}
}
