import { escapeRegex } from 'app/common/utils/utils.string';
import { SearchRequest } from '@twrx-api-models';
import { NotificationType } from 'app/models/domain/notification/notification-type';

//system
export const locale: string = 'en-US';

//local Db
export const exceptionLocalDbStorageName: string = 'exceptionStorage';

export const exceptionLocalDbName: string = 'exceptionDb';

//datetime
export const datePickerFormat: string = 'YYYY-MM-DD';

export const dateFormat: string = 'MM-dd-yyyy';

export const dateTimeFormat: string = 'MM-dd-yyyy (h:mm a)';

export const dateTimeParseFormat: string = 'yyyy-MM-ddTHH:mm:ss';

export const monthYearFormat: string = 'MMMM YYYY';

export const shortMonthYearFormat: string = 'MMM YYYY';

export const minDate: Date = new Date('1901-01-01');

export const minYear: number = 1900;

export const maxYear: number = 2100;

export const daysInYear: number = 365;

//validation
export const maxRate: number = 99.99;

export const maxRebateTotal: number = 999_999_999.99;

export const maxDaysSupply: number = 365;

export const maxQuantity: number = 999_999_999.99;

export const maxAvgDose: number = 999_999_999.99;

export const npiLength: number = 10;

export const minValidNcpdpLength: number = 6;

export const ncpdpLength: number = 7;

export const maxPasswordLength: number = 64;

export const minPasswordLength: number = 8;

export const maxTitleLength: number = 50;

export const maxContentLength: number = 5000;

export const defaultMaxNameLength: number = 50;

export const minSubmissionYear: number = new Date().getFullYear() - 1;

export const maxSubmissionYear: number = new Date().getFullYear() + 5;

export const maxConversionCoefficient: number = 999_999_999.99;

export const minConversionCoefficient: number = -999_999_999.99;

export const allowedPasswordSpecialSymbols: string =
	'@$!*?&._+(){}\\/|=;:\'"~`[]';

export const escapedAllowedPasswordSpecialSymbols: string = escapeRegex(
	allowedPasswordSpecialSymbols
);

//masks
export const nonSymbolPattern: RegExp = /[a-zA-Z0-9]/g;

export const allowedSpecialSymbolsPattern: RegExp = new RegExp(
	`[^${escapedAllowedPasswordSpecialSymbols}]`
);

export const passwordPattern: RegExp = new RegExp(
	`^(?=.*[A-Za-z])(?=.*\\d)(?=.*[${escapedAllowedPasswordSpecialSymbols}])[A-Za-z\\d${escapedAllowedPasswordSpecialSymbols}]{${minPasswordLength},}$`
);

export const urlPattern: RegExp =
	/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

export const ncpdpPattern: RegExp = /\d{6,7}/;

export const npiPattern: RegExp = /\d{10}/;

export const ndcMask: string = '00000-0000-00';

export const verifyCodeMask: string = '000000';

export const ncpdpMask: string = '0000000||000000';

export const npiMask: string = '0000000000';

export const emailPattern: RegExp = /^[\w.]+@([\w-]+\.)+\w{2,4}$/;

export const integerMask: string = 'separator.';

export const decimalMask: string = 'separator.4';

//periods
export const defaultNotificationDuration: number = 5_000;

export const longMessageNotificationDuration: number = 10_000;

export const reportsStatusesUpdatePeriod: number = 10_000;

export const rebatesLoadFilesStatusesUpdatePeriod: number = 5_000;

export const rejectsLoadFilesStatusesUpdatePeriod: number = 5_000;

export const notificationUpdatePeriod: number = 20_000;

export const generatedFileUpdatePeriod: number = 10_000;

export const bulUpdateDisabledUpdatePeriod: number = 5_000;

export const conversionTableUpdatePeriod: number = 120_000;

export const customerMonthlyDataUpdatePeriod = 10_000;

export const pharmacyIdReviewUpdatePeriod = 5_000;

export const listItemTooltipShowDelay: number = 700;

export const debounceTimeInMs: number = 300;

export const inputDebounceInMs: number = 700;

export const inputChangesThrottleTimePeriod: number = 300;

export const maxTimestamp: number = 8640000000000000;

// search
export const pageSizeOptions: number[] = [25, 50, 100];

export const defaultSearchRequest: SearchRequest = {
	pageIndex: 0,
	pageSize:  25,
	sortAsc:   true
} as SearchRequest;

export const minSearchableLength: number = 2;

export const minDuplicateSearchLength: number = 2;

export const maxDuplicateSearchLength: number = 256;

// infinite scroll
export const infiniteScrollDistance: number = 0.8;

export const infiniteScrollBatchSize: number = 20;

export const infiniteScrollDebounce: number = 1_500;

//files
export const bytesInMB: number = 1_048_576;

export const fileUploadChunkSizeInMB: number = 10;

export const importFileFormats: string = '.xls,.xlsx,.csv,.txt';

export const excelFileFormats: string = '.xls,.xlsx';

export const ndcListFileFormats: string = '.csv,.txt';

//other
export const confirmedDialogCloseResult: string = 'confirmed';

export const defaultNotificationType: NotificationType =
	NotificationType.Success;

export const hiddenScopes: string[] = ['Hangfire', 'Audit Log'];

export const emptyCellValue: string = '-';

export const defaultCountry: string = 'USA';

export const ncpdpFiller: string = '0';

// prettier-ignore
export const defaultStates: string[] = [
	'ID', 'IA', 'AL', 'AK',
	'AZ', 'AR', 'WY', 'WA',
	'VT', 'VA', 'WI', 'HI',
	'DE', 'GA', 'WV', 'IL',
	'IN', 'CA', 'KS', 'KY',
	'CO', 'CT', 'LA', 'MA',
	'MN', 'MS', 'MO', 'MI',
	'MT', 'ME', 'MD', 'NE',
	'NV', 'NH', 'NJ', 'NY',
	'NM', 'OH', 'OK', 'DC',
	'OR', 'PA', 'RI', 'ND',
	'NC', 'TN', 'TX', 'FL',
	'SD', 'SC', 'UT'
];

export const highlightClass: string = 'bg-gray-50';
