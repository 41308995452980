import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { VerticalNavigationBasicItemComponent } from 'app/layout/layouts/classic/vertical/components/basic/basic.component';
import { VerticalNavigationDividerItemComponent } from 'app/layout/layouts/classic/vertical/components/divider/divider.component';
import { VerticalNavigationSpacerItemComponent } from 'app/layout/layouts/classic/vertical/components/spacer/spacer.component';
import { VerticalNavigationComponent } from 'app/layout/layouts/classic/vertical/vertical.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector:        'app-vertical-navigation-group-item',
	templateUrl:     './group.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports:         [
		NgClass,
		NgIf,
		MatIconModule,
		NgFor,
		VerticalNavigationBasicItemComponent,
		VerticalNavigationDividerItemComponent,
		forwardRef(() => VerticalNavigationGroupItemComponent),
		VerticalNavigationSpacerItemComponent,
		VerticalNavigationSpacerItemComponent
	]
})
export class VerticalNavigationGroupItemComponent implements OnInit, OnDestroy {
	static ngAcceptInputType_autoCollapse: BooleanInput;

	@Input() autoCollapse: boolean;
	@Input() item: FuseNavigationItem;
	@Input() name: string;

	private verticalNavigationComponent: VerticalNavigationComponent;
	private unsubscribeAll: Subject<unknown> = new Subject<unknown>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private fuseNavigationService: FuseNavigationService
	) {}

	ngOnInit(): void {
		// Get the parent navigation component
		this.verticalNavigationComponent =
			this.fuseNavigationService.getComponent(this.name);

		// Subscribe to onRefreshed on the navigation component
		this.verticalNavigationComponent.onRefreshed
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				// Mark for check
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this.unsubscribeAll.next(null);
		this.unsubscribeAll.complete();
	}

	trackByFn(index: number, item: FuseNavigationItem): unknown {
		return item.id || item.children || index;
	}
}
