import { createAction, props } from '@ngrx/store';
import {
	RebateAggregatorModel,
	SearchResponse,
	ListOptionModel,
	CreateRebateAggregatorModel,
	UpdateRebateAggregatorModel,
	RebateAggregatorSearchModel,
	SearchRequest
} from '@twrx-api-models';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum RebateAggregatorActions {
	LoadRebatesAggregators = '[RebateAggregator] LoadRebatesAggregators',
	RebatesAggregatorsLoaded = '[RebateAggregator] RebatesAggregatorsLoaded',

	LoadRebateAggregatorOptions = '[RebateAggregator] LoadRebateAggregatorOptions',
	RebateAggregatorOptionsLoaded = '[RebateAggregator] RebateAggregatorOptionsLoaded',

	CreateRebateAggregator = '[RebateAggregator] CreateRebateAggregator',
	RebateAggregatorCreated = '[RebateAggregator] RebateAggregatorCreated',

	UpdateRebateAggregator = '[RebateAggregator] UpdateRebateAggregator',
	RebateAggregatorUpdated = '[RebateAggregator] RebateAggregatorUpdated',

	LoadRebateAggregatorById = '[RebateAggregator] LoadRebateAggregatorById',
	LoadRebateAggregatorByIdNotFound = '[RebateAggregator] LoadRebateAggregatorByIdNotFound',
	RebateAggregatorByIdLoaded = '[RebateAggregator] RebateAggregatorByIdLoaded',

	PreLoadRebateAggregator = '[RebateAggregator] PreLoadRebateAggregator',
	PreLoadRebateAggregatorNotFound = '[RebateAggregator] PreLoadRebateAggregatorNotFound',
	RebateAggregatorPreLoaded = '[RebateAggregator] RebateAggregatorPreLoaded',

	ErrorOccurred = '[RebateAggregator] ErrorOccurred'
}

export const loadRebatesAggregators = createAction(
	RebateAggregatorActions.LoadRebatesAggregators,
	props<ActionRequestPayload<SearchRequest>>()
);

export const rebatesAggregatorsLoaded = createAction(
	RebateAggregatorActions.RebatesAggregatorsLoaded,
	props<ActionResponsePayload<SearchResponse<RebateAggregatorSearchModel>>>()
);

export const loadRebateAggregatorOptions = createAction(
	RebateAggregatorActions.LoadRebateAggregatorOptions
);

export const rebateAggregatorOptionsLoaded = createAction(
	RebateAggregatorActions.RebateAggregatorOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const createRebateAggregator = createAction(
	RebateAggregatorActions.CreateRebateAggregator,
	props<ActionResponsePayload<CreateRebateAggregatorModel>>()
);

export const rebateAggregatorCreated = createAction(
	RebateAggregatorActions.RebateAggregatorCreated
);

export const updateRebateAggregator = createAction(
	RebateAggregatorActions.UpdateRebateAggregator,
	props<ActionResponsePayload<UpdateRebateAggregatorModel>>()
);

export const rebateAggregatorUpdated = createAction(
	RebateAggregatorActions.RebateAggregatorUpdated
);

export const preLoadRebateAggregator = createAction(
	RebateAggregatorActions.PreLoadRebateAggregator,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadRebateAggregatorNotFound = createAction(
	RebateAggregatorActions.PreLoadRebateAggregatorNotFound
);

export const rebateAggregatorPreLoaded = createAction(
	RebateAggregatorActions.RebateAggregatorPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<RebateAggregatorModel>>>()
);

export const loadRebateAggregatorById = createAction(
	RebateAggregatorActions.LoadRebateAggregatorById,
	props<ActionRequestPayload<number>>()
);

export const loadRebateAggregatorByIdNotFound = createAction(
	RebateAggregatorActions.LoadRebateAggregatorByIdNotFound,
	props<ActionRequestPayload<number>>()
);

export const rebateAggregatorByIdLoaded = createAction(
	RebateAggregatorActions.RebateAggregatorByIdLoaded,
	props<ActionResponsePayload<RebateAggregatorModel>>()
);

export const errorOccurred = createAction(
	RebateAggregatorActions.ErrorOccurred
);
