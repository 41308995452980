export enum PharmacyIdReviewActionModel {
	UseNcpdpFromFile = 1,
	UseNcpdpFromFileAndUpdateLocation = 2,
	UseNcpdpFromLocation = 3,
	UsePharmacyIdFromCustomer = 4,
	UseNcpdpFromDataQ = 5,
	DeleteExcludedPharmacy = 6,
	UseNcpdpFromDataQAndUpdateLocation = 7,
	UseNpiFromFile = 8,
	RejectBlankNcpdp = 9
}
