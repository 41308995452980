import { createAction, props } from '@ngrx/store';
import {
	FilterSearchRequest,
	RebatesReSubmissionFileModel,
	RebatesReSubmissionFileSearchFilterModel,
	RebatesReSubmissionFileStatusModel,
	RebatesReSubmissionStatusChangedNotification,
	SearchResponse
} from '@twrx-api-models';
import { RebatesReSubmissionUploadModel } from 'app/models/dto/rebates-re-submission-upload-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum RebatesReSubmissionActions {
	LoadRebatesReSubmissionFiles = '[RebatesReSubmissionActions] LoadRebatesReSubmissionFiles',
	RebatesReSubmissionFilesLoaded = '[RebatesReSubmissionActions] RebatesReSubmissionFilesLoaded',

	LoadRebatesReSubmissionFilesStatuses = '[RebatesReSubmissionActions] LoadRebatesReSubmissionFilesStatuses',
	RebatesReSubmissionFilesStatusesLoaded = '[RebatesReSubmissionActions] RebatesReSubmissionFilesStatusesLoaded',

	UploadRebatesReSubmissionFiles = '[RebatesReSubmissionActions] UploadRebatesReSubmissionFiles',
	RebatesReSubmissionFilesUploading = '[RebatesReSubmissionActions] RebatesReSubmissionFilesUploading',
	RebatesReSubmissionFilesUploaded = '[RebatesReSubmissionActions] RebatesReSubmissionFilesUploaded',

	DownloadRebatesReSubmissionFileTemplate = '[RebatesReSubmissionActions] DownloadRebatesReSubmissionFileTemplate',
	RebatesReSubmissionFileTemplateDownloaded = '[RebatesReSubmissionActions] RebatesReSubmissionFileTemplateDownloaded',

	RebatesReSubmissionFileStatusUpdatedEvent = '[RebatesReSubmissionActions] RebatesReSubmissionFileStatusUpdatedEvent',

	ErrorOccurred = '[RebatesReSubmissionActions] Error Occurred'
}

// load Rebates Re-Submission files
export const loadRebatesReSubmissionFiles = createAction(
	RebatesReSubmissionActions.LoadRebatesReSubmissionFiles,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesReSubmissionFileSearchFilterModel>
		>
	>()
);

export const rebatesReSubmissionFilesLoaded = createAction(
	RebatesReSubmissionActions.RebatesReSubmissionFilesLoaded,
	props<ActionResponsePayload<SearchResponse<RebatesReSubmissionFileModel>>>()
);

// load Rebates Re-Submission files statuses
export const loadRebatesReSubmissionFilesStatuses = createAction(
	RebatesReSubmissionActions.LoadRebatesReSubmissionFilesStatuses,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesReSubmissionFileSearchFilterModel>
		>
	>()
);

export const rebatesReSubmissionFilesStatusesLoaded = createAction(
	RebatesReSubmissionActions.RebatesReSubmissionFilesStatusesLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<RebatesReSubmissionFileStatusModel>
		>
	>()
);

// upload Rebates Re-Submission file
export const uploadRebatesReSubmissionFiles = createAction(
	RebatesReSubmissionActions.UploadRebatesReSubmissionFiles,
	props<ActionRequestPayload<RebatesReSubmissionUploadModel>>()
);

export const rebatesReSubmissionFilesUploading = createAction(
	RebatesReSubmissionActions.RebatesReSubmissionFilesUploading,
	props<ActionResponsePayload<number>>()
);

export const rebatesReSubmissionFilesUploaded = createAction(
	RebatesReSubmissionActions.RebatesReSubmissionFilesUploaded,
	props<ActionResponsePayload<number>>()
);

export const downloadRebatesReSubmissionFileTemplate = createAction(
	RebatesReSubmissionActions.DownloadRebatesReSubmissionFileTemplate,
	props<ActionResponsePayload<void>>()
);

export const rebatesReSubmissionFileTemplateDownloaded = createAction(
	RebatesReSubmissionActions.RebatesReSubmissionFileTemplateDownloaded
);

// Notifications
export const rebatesReSubmissionFileStatusUpdated = createAction(
	RebatesReSubmissionActions.RebatesReSubmissionFileStatusUpdatedEvent,
	props<ActionResponsePayload<RebatesReSubmissionStatusChangedNotification>>()
);

export const errorOccurred = createAction(
	RebatesReSubmissionActions.ErrorOccurred
);
