import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountRoutes } from 'app/common/routes';
import { isPermitted } from 'app/common/utils/utils.auth';
import { catchAppError } from 'app/common/utils/utils.error';
import { isNullOrUndefined } from 'app/common/utils/utils.object';
import { PermissionModel } from '@twrx-api-models';
import { authenticate } from 'app/store/auth-proxy/auth-proxy.actions';
import {
	selectIsAuthenticated,
	selectPermissions
} from 'app/store/auth-proxy/auth-proxy.selectors';
import { AuthProxyState } from 'app/store/auth-proxy/auth-proxy.state';
import { map, of, switchMap } from 'rxjs';

export const AuthGuard: (
	permissions?: PermissionModel
) => CanActivateFn | CanActivateChildFn =
	(permission?: PermissionModel) => () => {
		const store = inject(Store<AuthProxyState>);
		const router = inject(Router);
		const location = inject(Location);

		const isRoutePermitted$ = store.select(selectPermissions).pipe(
			map((permissions: PermissionModel[]) => {
				const isRoutePermitted = isPermitted(permissions, permission);

				if (!isRoutePermitted) {
					router
						.navigate([AccountRoutes.NoPermissions])
						.catch(catchAppError);
				}

				return isRoutePermitted;
			})
		);

		return store.select(selectIsAuthenticated).pipe(
			switchMap(isAuthenticated => {
				if (!isAuthenticated) {
					store.dispatch(
						authenticate({ redirectUrl: location.path() })
					);

					return of(false);
				}

				if (isNullOrUndefined(permission)) {
					return of(true);
				}

				return isRoutePermitted$;
			})
		);
	};
