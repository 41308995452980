import { createAction, props } from '@ngrx/store';
import {
	SearchResponse,
	ListOptionModel,
	RebateAggregatorFormularyModel,
	CreateRebateAggregatorFormularyModel,
	UpdateRebateAggregatorFormularyModel,
	RebateAggregatorFormularyFilterModel,
	FilterSearchRequest,
	RebateAggregatorFormularySearchModel,
	RebateAggregatorFormularyOptionFilterModel
} from '@twrx-api-models';
import { RebateAggregatorFormularyRequestModel } from 'app/models/dto/rebate-aggregator-formulary-request-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum RebateAggregatorFormularyActions {
	LoadRebateAggregatorFormulariesOptions = '[RebateAggregatorFormulary] LoadRebateAggregatorFormulariesOptions',
	RebateAggregatorFormulariesOptionsLoaded = '[RebateAggregatorFormulary] RebateAggregatorFormulariesOptionsLoaded',
	ClearRebateAggregatorFormulariesOptions = '[RebateAggregatorFormulary] ClearRebateAggregatorFormulariesOptions',

	ResetRebateAggregatorFormulariesOptions = '[RebateAggregatorFormulary] ResetRebateAggregatorFormulariesOptions',
	RebateAggregatorFormulariesOptionsReseted = '[RebateAggregatorFormulary] RebateAggregatorFormulariesOptionsReseted',

	LoadRebateAggregatorAllFormulariesOptions = '[RebateAggregatorFormulary] LoadRebateAggregatorAllFormulariesOptions',
	RebateAggregatorAllFormulariesOptionsLoaded = '[RebateAggregatorFormulary] RebateAggregatorAllFormulariesOptionsLoaded',

	CreateRebateAggregatorFormulary = '[RebateAggregatorFormulary] CreateRebateAggregatorFormulary',
	RebateAggregatorFormularyCreated = '[RebateAggregatorFormulary] RebateAggregatorFormularyCreated',

	UpdateRebateAggregatorFormulary = '[RebateAggregatorFormulary] UpdateRebateAggregatorFormulary',
	RebateAggregatorFormularyUpdated = '[RebateAggregatorFormulary] RebateAggregatorFormularyUpdated',

	DeleteRebateAggregatorFormulary = '[RebateAggregatorFormulary] DeleteRebateAggregatorFormulary',
	RebateAggregatorFormularyDeleted = '[RebateAggregatorFormulary] RebateAggregatorFormularyDeleted',

	PreLoadRebateAggregatorFormulary = '[RebateAggregatorFormulary] PreLoadRebateAggregatorFormulary',
	PreLoadRebateAggregatorFormularyNotFound = '[RebateAggregatorFormulary] PreLoadRebateAggregatorFormularyNotFound',
	RebateAggregatorFormularyPreLoaded = '[RebateAggregatorFormulary] RebateAggregatorFormularyPreLoaded',

	LoadRebateAggregatorFormularyById = '[RebateAggregatorFormulary] LoadRebateAggregatorFormularyById',
	LoadRebateAggregatorFormularyByIdNotFound = '[RebateAggregatorFormulary] LoadRebateAggregatorFormularyByIdNotFound',
	RebateAggregatorFormularyByIdLoaded = '[RebateAggregatorFormulary] RebateAggregatorFormularyByIdLoaded',

	LoadRebateAggregatorFormularies = '[RebateAggregatorFormulary] LoadRebateAggregatorFormularies',
	RebateAggregatorFormulariesLoaded = '[RebateAggregatorFormulary] RebateAggregatorFormulariesLoaded',

	ErrorOccurred = '[RebateAggregatorFormulary] ErrorOccurred'
}

export const createRebateAggregatorFormulary = createAction(
	RebateAggregatorFormularyActions.CreateRebateAggregatorFormulary,
	props<ActionResponsePayload<CreateRebateAggregatorFormularyModel>>()
);

export const rebateAggregatorFormularyCreated = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormularyCreated,
	props<ActionResponsePayload<number>>()
);

export const updateRebateAggregatorFormulary = createAction(
	RebateAggregatorFormularyActions.UpdateRebateAggregatorFormulary,
	props<ActionResponsePayload<UpdateRebateAggregatorFormularyModel>>()
);

export const rebateAggregatorFormularyUpdated = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormularyUpdated,
	props<ActionResponsePayload<number>>()
);

export const deleteRebateAggregatorFormulary = createAction(
	RebateAggregatorFormularyActions.DeleteRebateAggregatorFormulary,
	props<ActionResponsePayload<number>>()
);

export const rebateAggregatorFormularyDeleted = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormularyDeleted
);

export const preLoadRebateAggregatorFormulary = createAction(
	RebateAggregatorFormularyActions.PreLoadRebateAggregatorFormulary,
	props<ActionRequestPayload<RebateAggregatorFormularyRequestModel>>()
);

export const preLoadRebateAggregatorFormularyNotFound = createAction(
	RebateAggregatorFormularyActions.PreLoadRebateAggregatorFormularyNotFound
);

export const rebateAggregatorFormularyPreLoaded = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormularyPreLoaded,
	props<ActionResponsePayload<RebateAggregatorFormularyModel>>()
);

export const loadRebateAggregatorFormularyById = createAction(
	RebateAggregatorFormularyActions.LoadRebateAggregatorFormularyById,
	props<ActionRequestPayload<RebateAggregatorFormularyRequestModel>>()
);

export const loadRebateAggregatorFormularyByIdNotFound = createAction(
	RebateAggregatorFormularyActions.LoadRebateAggregatorFormularyByIdNotFound
);

export const rebateAggregatorFormularyByIdLoaded = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormularyByIdLoaded,
	props<ActionResponsePayload<RebateAggregatorFormularyModel>>()
);

export const loadRebateAggregatorFormularies = createAction(
	RebateAggregatorFormularyActions.LoadRebateAggregatorFormularies,
	props<
		ActionResponsePayload<
			FilterSearchRequest<RebateAggregatorFormularyFilterModel>
		>
	>()
);

export const rebateAggregatorFormulariesLoaded = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormulariesLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<RebateAggregatorFormularySearchModel>
		>
	>()
);

export const loadRebateAggregatorFormulariesOptions = createAction(
	RebateAggregatorFormularyActions.LoadRebateAggregatorFormulariesOptions,
	props<ActionRequestPayload<RebateAggregatorFormularyOptionFilterModel>>()
);

export const rebateAggregatorFormulariesOptionsLoaded = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormulariesOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const clearRebateAggregatorFormulariesOptions = createAction(
	RebateAggregatorFormularyActions.ClearRebateAggregatorFormulariesOptions
);

export const loadRebateAggregatorAllFormulariesOptions = createAction(
	RebateAggregatorFormularyActions.LoadRebateAggregatorAllFormulariesOptions
);

export const rebateAggregatorAllFormulariesOptionsLoaded = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorAllFormulariesOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const resetRebateAggregatorFormulariesOptions = createAction(
	RebateAggregatorFormularyActions.ResetRebateAggregatorFormulariesOptions
);

export const rebateAggregatorFormulariesOptionsReseted = createAction(
	RebateAggregatorFormularyActions.RebateAggregatorFormulariesOptionsReseted,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const errorOccurred = createAction(
	RebateAggregatorFormularyActions.ErrorOccurred
);
