import { Injectable } from '@angular/core';
import { createChat } from '@n8n/chat';
import { environment } from 'environments/environment';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class ChatGptService {
	private chatId: string = 'n8n-chat';

	constructor(private readonly translocoService: TranslocoService) {}

	public createChatGpt(): void {
		createChat({
			webhookUrl:    environment.n8nChatWebhookUrl,
			webhookConfig: {
				method:  'POST',
				headers: {}
			},
			target:                `#${this.chatId}`,
			mode:                  'window',
			showWindowCloseButton: false,
			showWelcomeScreen:     false,
			loadPreviousSession:   false,
			chatInputKey:          'chatInput',
			chatSessionKey:        'sessionId',
			metadata:              {},
			defaultLanguage:       'en',
			initialMessages:       [],
			i18n:                  {
				en: {
					title: this.translocoService.translate('chatGpt.title'),
					subtitle:
						this.translocoService.translate('chatGpt.subtitle'),
					footer:           '',
					getStarted:       '',
					inputPlaceholder: this.translocoService.translate(
						'chatGpt.inputPlaceholder'
					),
					closeButtonTooltip: ''
				}
			},
			allowFileUploads: false
		});
	}

	public removeChatGpt(): void {
		const chat = document.getElementById(this.chatId);

		if (chat) {
			chat.remove();
		}
	}
}
