export enum DispenserClassCodeModel {
	NotUsed = 0,
	IndependentPharmacy = 1,
	ChainPharmacy = 2,
	AlternateDispensingSite1 = 3,
	AlternateDispensingSite2 = 4,
	FranchisePharmacy = 5,
	GovernmentPharmacy = 6,
	AlternateDispensingSite = 7
}
