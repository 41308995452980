import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { EventNotifierService } from 'app/services/event-notifier.service';
import {
	appInitialized,
	signOut
} from 'app/store/auth-proxy/auth-proxy.actions';
import { selectIsAuthenticated } from 'app/store/auth-proxy/auth-proxy.selectors';
import { AuthProxyState } from 'app/store/auth-proxy/auth-proxy.state';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from 'environments/environment';
import { ApplicationInsightsService } from 'app/services/application-insights.service';
import { ChatGptService } from 'app/services/chat-gpt-service.service';

import { GeneratedFileNotificationService } from './services/notifications/generated-file-notification.service';

@Component({
	selector:    'app-root',
	templateUrl: './app.component.html',
	styleUrls:   ['./app.component.scss'],
	imports:     [RouterOutlet]
})
export class AppComponent implements OnInit, AfterViewInit {
	public isAuthenticated: boolean;

	private openTabsLocalStorageKey: string = 'twrxTabsOpened';

	constructor(
		private readonly store: Store<AuthProxyState>,
		private readonly eventNotifierService: EventNotifierService,
		private readonly bnIdle: BnNgIdleService,
		private readonly fileGenerationNotificationService: GeneratedFileNotificationService,
		private readonly appInsightsService: ApplicationInsightsService,
		private readonly chatGptService: ChatGptService
	) {}

	ngOnInit(): void {
		this.eventNotifierService.startListen();
		this.appInsightsService.loadAppInsights();

		this.store.select(selectIsAuthenticated).subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;

			if (this.isAuthenticated) {
				this.fileGenerationNotificationService.registerHandlers();
			}

			if (this.isAuthenticated && environment.n8nChatWebhookUrl) {
				this.chatGptService.createChatGpt();
			} else {
				this.chatGptService.removeChatGpt();
			}
		});

		this.store.dispatch(appInitialized());
	}

	ngAfterViewInit(): void {
		this.bnIdle
			.startWatching(environment.idleTimeoutInSeconds)
			.subscribe((isTimedOut: boolean) => {
				if (isTimedOut) {
					this.handleIdleTimeout();
				}
			});

		this.handleTabOpened();

		window.onbeforeunload = (): void => {
			this.handleTabSuspended();
		};
	}

	private getOpenTabsCount = (): number => {
		const value = localStorage.getItem(this.openTabsLocalStorageKey);
		const result = parseInt(value, 10);

		return !Number.isNaN(result) ? result : 0;
	};

	private handleTabOpened = (): void => {
		const tabsOpened = this.getOpenTabsCount() + 1;

		localStorage.setItem(this.openTabsLocalStorageKey, `${tabsOpened}`);
	};

	private handleTabSuspended = (): void => {
		const tabsBeforeSuspending = this.getOpenTabsCount();
		const tabsOpened =
			tabsBeforeSuspending > 0 ? tabsBeforeSuspending - 1 : 0;

		localStorage.setItem(this.openTabsLocalStorageKey, `${tabsOpened}`);
	};

	private handleIdleTimeout = (): void => {
		this.handleTabSuspended();

		if (this.isAuthenticated && this.getOpenTabsCount() <= 0) {
			this.store.dispatch(signOut());
		}
	};
}
