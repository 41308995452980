import { createAction, props } from '@ngrx/store';
import {
	ListOptionModel,
	RejectLoadFileProcessModel,
	RejectsLoadFileDetailsModel,
	RejectsLoadFileSearchFilterModel,
	RejectsLoadFileSearchModel,
	RejectsLoadMappingModel,
	RejectsLoadStatistic,
	RejectsLoadStatisticRequest,
	RejectsLoadTemplateSaveModel,
	FilterSearchRequest,
	SearchResponse,
	RejectLoadFileStatus
} from '@twrx-api-models';
import { RejectsUploadModel } from 'app/models/dto/rejects-upload-model';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { ActionResponsePayload } from 'app/store/action-response-payload';

export enum RejectsLoadActions {
	LoadRejectsLoadFiles = '[RejectsLoad] LoadRejectsLoadFiles',
	RejectsLoadFilesLoaded = '[RejectsLoad] RejectsLoadFilesLoaded',

	LoadRejectsLoadFilesStatuses = '[RejectsLoad] LoadRejectsLoadFilesStatuses',
	RejectsLoadFilesStatusesLoaded = '[RejectsLoad] RejectsLoadFilesStatusesLoaded',

	LoadRejectsLoadFileStatistic = '[RejectsLoad] LoadRejectsLoadFileStatistic',
	RejectsLoadFileStatisticNotFound = '[RejectsLoad] RejectsLoadFileStatisticNotFound',
	RejectsLoadFileStatisticLoaded = '[RejectsLoad] RejectsLoadFileStatisticLoaded',

	LoadRejectsLoadPeriodStatistic = '[RejectsLoad] LoadRejectsLoadPeriodStatistic',
	RejectsLoadPeriodStatisticLoaded = '[RejectsLoad] RejectsLoadPeriodStatisticLoaded',

	PreloadRejectsLoadFileDetails = '[RejectsLoad] PreloadRejectsLoadFileDetails',
	PreloadedRejectsLoadFileNotFound = '[RejectsLoad] PreloadedRejectsLoadFileNotFound',
	RejectsLoadFileDetailsPreloaded = '[RejectsLoad] RejectsLoadFileDetailsPreloaded',

	LoadRejectsLoadFileDetails = '[RejectsLoad] LoadRejectsLoadFileDetails',
	LoadedRejectsLoadFileNotFound = '[RejectsLoad] RejectsLoadFileNotFound',
	RejectsLoadFileDetailsLoaded = '[RejectsLoad] RejectsLoadFileDetailsLoaded',

	AddRejectsLoadFile = '[RejectsLoad] AddRejectsLoadFile',
	RejectsLoadFileLoading = '[RejectsLoad] AddRejectsFileLoading',
	RejectsLoadFileAdded = '[RejectsLoad] RejectsLoadFileAdded',

	LoadRejectsLoadTemplates = '[RejectsLoad] LoadRejectsLoadTemplates',
	RejectsLoadTemplatesLoaded = '[RejectsLoad] RejectsLoadTemplatesLoaded',

	LoadRejectsLoadYears = '[RejectsLoad] LoadRejectsLoadYears',
	RejectsLoadYearsLoaded = '[RejectsLoad] RejectsLoadTemplatesYears',

	LoadRejectsLoadTemplateMapping = '[RejectsLoad] LoadRejectsLoadTemplateMapping',
	RejectsLoadTemplateMappingLoaded = '[RejectsLoad] RejectsLoadTemplateMappingLoaded',

	SaveRejectsLoadTemplate = '[RejectsLoad] SaveRejectsLoadTemplate',
	RejectsLoadTemplateSaved = '[RejectsLoad] RejectsLoadTemplateSaved',

	DeleteRejectsLoadTemplate = '[RejectsLoad] DeleteRejectsLoadTemplate',
	RejectsLoadTemplateDeleted = '[RejectsLoad] RejectsLoadTemplateDeleted',

	ProcessRejectsLoadFile = '[RejectsLoad] ProcessRejectsLoadFile',
	RejectsLoadFileProcessed = '[RejectsLoad] RejectsLoadFileProcessed',

	ValidateAndProcess = '[RejectsLoad] ValidateAndProcessStarted',

	ErrorOccurred = '[RejectsLoad] ErrorOccurred'
}

//Load Files
export const loadRejectsLoadFiles = createAction(
	RejectsLoadActions.LoadRejectsLoadFiles,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RejectsLoadFileSearchFilterModel>
		>
	>()
);

export const rejectsLoadFilesLoaded = createAction(
	RejectsLoadActions.RejectsLoadFilesLoaded,
	props<ActionResponsePayload<SearchResponse<RejectsLoadFileSearchModel>>>()
);

//Load Statuses
export const loadRejectsLoadFilesStatuses = createAction(
	RejectsLoadActions.LoadRejectsLoadFilesStatuses,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RejectsLoadFileSearchFilterModel>
		>
	>()
);

export const rejectsLoadFilesStatusesLoaded = createAction(
	RejectsLoadActions.RejectsLoadFilesStatusesLoaded,
	props<ActionResponsePayload<RejectLoadFileStatus[]>>()
);

//Process File
export const processRejectsLoadFile = createAction(
	RejectsLoadActions.ProcessRejectsLoadFile,
	props<ActionRequestPayload<RejectLoadFileProcessModel>>()
);

export const rejectsLoadFileProcessed = createAction(
	RejectsLoadActions.RejectsLoadFileProcessed
);

//Load File Statistic
export const loadRejectsLoadFileStatistic = createAction(
	RejectsLoadActions.LoadRejectsLoadFileStatistic,
	props<ActionRequestPayload<number>>()
);

export const rejectsLoadFileStatisticNotFound = createAction(
	RejectsLoadActions.RejectsLoadFileStatisticNotFound
);

export const rejectsLoadFileStatisticLoaded = createAction(
	RejectsLoadActions.RejectsLoadFileStatisticLoaded,
	props<ActionResponsePayload<RejectsLoadStatistic>>()
);

//Load Period Statistic
export const loadRejectsLoadPeriodStatistic = createAction(
	RejectsLoadActions.LoadRejectsLoadPeriodStatistic,
	props<ActionRequestPayload<RejectsLoadStatisticRequest>>()
);

export const rejectsLoadPeriodStatisticLoaded = createAction(
	RejectsLoadActions.RejectsLoadPeriodStatisticLoaded,
	props<ActionResponsePayload<RejectsLoadStatistic>>()
);

//Preload File Details
export const preloadRejectsLoadFileDetails = createAction(
	RejectsLoadActions.PreloadRejectsLoadFileDetails,
	props<ActionRequestPayload<number>>()
);

export const rejectsLoadFileDetailsPreloaded = createAction(
	RejectsLoadActions.RejectsLoadFileDetailsPreloaded,
	props<ActionResponsePayload<RejectsLoadFileDetailsModel>>()
);

export const preloadedRejectsLoadFileNotFound = createAction(
	RejectsLoadActions.PreloadedRejectsLoadFileNotFound
);

//Load File Details
export const loadRejectsLoadFileDetails = createAction(
	RejectsLoadActions.LoadRejectsLoadFileDetails,
	props<ActionRequestPayload<number>>()
);

export const rejectsLoadFileDetailsLoaded = createAction(
	RejectsLoadActions.RejectsLoadFileDetailsLoaded,
	props<ActionResponsePayload<RejectsLoadFileDetailsModel>>()
);

export const loadedRejectsLoadFileNotFound = createAction(
	RejectsLoadActions.LoadedRejectsLoadFileNotFound
);

//Upload New File
export const addRejectsLoadFile = createAction(
	RejectsLoadActions.AddRejectsLoadFile,
	props<ActionRequestPayload<RejectsUploadModel>>()
);

export const rejectsLoadFileLoading = createAction(
	RejectsLoadActions.RejectsLoadFileLoading,
	props<ActionResponsePayload<number>>()
);

export const rejectsLoadFileAdded = createAction(
	RejectsLoadActions.RejectsLoadFileAdded,
	props<ActionResponsePayload<number>>()
);

//Load Templates
export const loadRejectsLoadTemplates = createAction(
	RejectsLoadActions.LoadRejectsLoadTemplates
);

export const rejectsLoadTemplatesLoaded = createAction(
	RejectsLoadActions.RejectsLoadTemplatesLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

//Load Years
export const loadRejectsLoadYears = createAction(
	RejectsLoadActions.LoadRejectsLoadYears
);

export const rejectsLoadYearsLoaded = createAction(
	RejectsLoadActions.RejectsLoadYearsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

//Load Template
export const loadRejectsLoadTemplateMapping = createAction(
	RejectsLoadActions.LoadRejectsLoadTemplateMapping,
	props<ActionRequestPayload<number>>()
);

export const rejectsLoadTemplateMappingLoaded = createAction(
	RejectsLoadActions.RejectsLoadTemplateMappingLoaded,
	props<ActionResponsePayload<RejectsLoadMappingModel>>()
);

//Save Template
export const saveRejectsLoadTemplate = createAction(
	RejectsLoadActions.SaveRejectsLoadTemplate,
	props<ActionRequestPayload<RejectsLoadTemplateSaveModel>>()
);

export const rejectsLoadTemplateSaved = createAction(
	RejectsLoadActions.RejectsLoadTemplateSaved,
	props<ActionResponsePayload<number>>()
);

//Delete Template
export const deleteRejectsLoadTemplate = createAction(
	RejectsLoadActions.DeleteRejectsLoadTemplate,
	props<ActionRequestPayload<number>>()
);

export const rejectsLoadTemplateDeleted = createAction(
	RejectsLoadActions.RejectsLoadTemplateDeleted
);

export const errorOccurred = createAction(RejectsLoadActions.ErrorOccurred);
