export enum RebatesLoadAuditEventTypeModel {
	UploadRebateLoadFile = 1,
	UpdateRebateLoadFileFileMapping = 2,
	SaveRebateLoadTemplate = 3,
	DeleteRebateLoadTemplate = 4,
	UpdateRebateInvalidRecords = 10,
	DeleteRebateInvalidRecordsByErrorCode = 11,
	BulkUpdateRebateInvalidRecords = 12,
	ResolveRebateInvalidRecordsViaBulkAction = 13,
	ChangeLocationMapping = 15,
	ExcludeLocations = 16,
	ReviewPharmacyIdRecord = 20,
	ResolveAllRebateLoadFileDuplicates = 30,
	ResolveRebateLoadFileRecordDuplicate = 31,
	ResolveAllCrossRebateLoadFilesDuplicates = 32,
	ResolveCrossRebateLoadFileRecordsDuplicates = 33,
	AggregateRebateLoadFile = 40,
	RebateLoadFileConverted = 50,
	ResetConversionType = 51,
	ResolveAberrantQuantityRecords = 52,
	ApplyConversionCoefficient = 60,
	MarkFilteredRebateRecordsAsDeleted = 61,
	SetRebateRecordsQuantity = 62,
	RecoveryJCodes = 63,
	RecoveryNdcJCodeCoefficients = 64,
	ProcessRebateLoadFile = 70,
	DeleteRebateLoadFile = 80,
	RollbackRebateLoadFile = 90,
	FinalReviewRebateLoadFile = 100
}
