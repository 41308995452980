import { createAction, props } from '@ngrx/store';
import {
	SearchResponse,
	CustomerCustomDateOfServiceSearchModel,
	UpdateCustomerCustomDateOfServiceModel,
	CreateCustomerCustomDateOfServiceModel,
	FilterSearchRequest,
	CustomerCustomDateOfServiceFilterModel,
	CustomerCustomDateOfServiceModel
} from '@twrx-api-models';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { FetchCustomerCustomDateOfServiceRequest } from 'app/models/dto/fetch-customer-custom-date-of-service-request';

import { ActionResponsePayload } from '../action-response-payload';
import { ActionRequestPayload } from '../action-request-payload';

export enum CustomerCustomDateOfServiceActions {
	LoadCustomDateOfServiceList = '[CustomerCustomDateOfService] LoadCustomDateOfServiceList',
	CustomDateOfServiceListLoaded = '[CustomerCustomDateOfService] CustomDateOfServiceListLoaded',

	PreLoadCustomDateOfService = '[CustomerCustomDateOfService] PreLoadCustomDateOfService',
	PreLoadCustomDateOfServiceNotFound = '[CustomerCustomDateOfService] PreLoadCustomDateOfServiceNotFound',
	CustomDateOfServicePreLoaded = '[CustomerCustomDateOfService] CustomDateOfServicePreLoaded',

	LoadCustomDateOfService = '[CustomerCustomDateOfService] LoadCustomDateOfService',
	LoadCustomDateOfServiceNotFound = '[CustomerCustomDateOfService] LoadCustomDateOfServiceNotFound',
	CustomDateOfServiceLoaded = '[CustomerCustomDateOfService] CustomDateOfServiceLoaded',

	CreateCustomDateOfService = '[CustomerCustomDateOfService] CreateCustomDateOfService',
	CustomDateOfServiceCreated = '[CustomerCustomDateOfService] CustomDateOfServiceCreated',

	UpdateCustomDateOfService = '[CustomerCustomDateOfService] UpdateCustomDateOfService',
	CustomDateOfServiceUpdated = '[CustomerCustomDateOfService] CustomDateOfServiceUpdated',

	DeleteCustomDateOfService = '[CustomerCustomDateOfService] DeleteCustomDateOfService',
	CustomDateOfServiceDeleted = '[CustomerCustomDateOfService] CustomDateOfServiceDeleted',

	ErrorOccurred = '[CustomerCustomDateOfService] ErrorOccurred'
}

export const loadCustomerCustomDateOfServiceList = createAction(
	CustomerCustomDateOfServiceActions.LoadCustomDateOfServiceList,
	props<
		ActionRequestPayload<
			FilterSearchRequest<CustomerCustomDateOfServiceFilterModel>
		>
	>()
);

export const customerCustomDateOfServiceListLoaded = createAction(
	CustomerCustomDateOfServiceActions.CustomDateOfServiceListLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<CustomerCustomDateOfServiceSearchModel>
		>
	>()
);

export const preLoadCustomerCustomDateOfService = createAction(
	CustomerCustomDateOfServiceActions.PreLoadCustomDateOfService,
	props<ActionRequestPayload<FetchCustomerCustomDateOfServiceRequest>>()
);

export const preLoadCustomerCustomDateOfServiceNotFound = createAction(
	CustomerCustomDateOfServiceActions.PreLoadCustomDateOfServiceNotFound
);

export const customerCustomDateOfServicePreLoaded = createAction(
	CustomerCustomDateOfServiceActions.CustomDateOfServicePreLoaded,
	props<
		ActionResponsePayload<IPreLoadModel<CustomerCustomDateOfServiceModel>>
	>()
);

export const loadCustomerCustomDateOfService = createAction(
	CustomerCustomDateOfServiceActions.LoadCustomDateOfService,
	props<ActionRequestPayload<FetchCustomerCustomDateOfServiceRequest>>()
);

export const loadCustomerCustomDateOfServiceFound = createAction(
	CustomerCustomDateOfServiceActions.LoadCustomDateOfServiceNotFound
);

export const customerCustomDateOfServiceLoaded = createAction(
	CustomerCustomDateOfServiceActions.CustomDateOfServiceLoaded,
	props<ActionResponsePayload<CustomerCustomDateOfServiceModel>>()
);

export const createCustomerCustomDateOfService = createAction(
	CustomerCustomDateOfServiceActions.CreateCustomDateOfService,
	props<ActionRequestPayload<CreateCustomerCustomDateOfServiceModel>>()
);

export const customerCustomDateOfServiceCreated = createAction(
	CustomerCustomDateOfServiceActions.CustomDateOfServiceCreated
);

export const updateCustomerCustomDateOfService = createAction(
	CustomerCustomDateOfServiceActions.UpdateCustomDateOfService,
	props<ActionRequestPayload<UpdateCustomerCustomDateOfServiceModel>>()
);

export const customerCustomDateOfServiceUpdated = createAction(
	CustomerCustomDateOfServiceActions.CustomDateOfServiceUpdated
);

export const deleteCustomerCustomDateOfService = createAction(
	CustomerCustomDateOfServiceActions.DeleteCustomDateOfService,
	props<ActionRequestPayload<number>>()
);

export const customerCustomDateOfServiceDeleted = createAction(
	CustomerCustomDateOfServiceActions.CustomDateOfServiceDeleted
);

export const errorOccurred = createAction(
	CustomerCustomDateOfServiceActions.ErrorOccurred
);
